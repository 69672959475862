module.exports = {
  SiteTitle: 'Inclusive Counselling',
  Sitelogo: '#',
  SiteLogoText: 'sitelogotext',
  SiteAuthor: '',
  SiteDescription: 'Counselling for everyone',
  defaultDescription: 'Counsellor', 
  SiteSocialLinks: {
    twitter: 'https://twitter.com/',
    linkedin: 'https://www.linkedin.com/in/',
  },
  SiteAddress: {
    city: 'Casablanca',
    region: 'CurvaSud',
    country: 'Morocco',
    zipCode: 'ZipCode',
  },
  SiteContact: {
    email: 'test@example.com',
    phone: 'phone number',
  },
  SiteCopyright: '2021',
};
